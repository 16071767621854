import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNew";
import Stack from "@mui/material/Stack";

const Title = ({ text, menuItems = [], ...otherProps }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        pb: 1,
        position: "sticky",
        top: 0,
        overflow: "hidden",
        bgcolor: "background.default",
      }}
      {...otherProps}
    >
      <Stack direction="row" alignItems="center" gap={3}>
        <IconButton
          sx={{ "& .MuiSvgIcon-root": { paddingRight: "5px" } }}
          component={RouterLink}
          aria-label="back-button"
          color="primary"
          size="large"
          to="/"
        >
          <ArrowBackIcon fontSize="inherit" />
        </IconButton>
        <Typography variant="h3" fontWeight="bold">
          {text}
        </Typography>
      </Stack>
      {menuItems.length > 0 && (
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          {menuItems.map(({ title, link, sx, props }) => (
            <Button key={title} href={link} sx={{ ...sx }} {...props}>
              {title}
            </Button>
          ))}
        </Box>
      )}
    </Stack>
  );
};

export default Title;
