import { RenderLink } from "../utils";

const blogPosts = [
  {
    title: "FAIR Data Spaces Final Event on December 3rd, 2024",
    date: "December 11, 2024",
    author: "Muhammad Hamza Akhtar",
    image: "./assets/blogs/fairds-event-2024.png",
    description: (
      <>
        We demonstrated our use cases using PADME at the FAIR Data Spaces Final
        Event. More information about the event can be found here.{" "}
        <RenderLink
          href="https://www.nfdi.de/fair-data-spaces-final-event-on-december-3rd-2024/?lang=en"
          text="FAIRDS Final Event '24"
        />
      </>
    ),
  },
  {
    title: "FAIR-DS4NFDI: Project Wiki Update & PADME PHT on FAIRDS Cloud",
    date: "October 11, 2024",
    author: "Muhammad Hamza Akhtar",
    image: "./assets/logo-black.png",
    smallWidth: true,
    description: (
      <>
        As FAIR-DS4NFDI team 🚀, We're pleased to share the latest state of the
        project wiki page. If you're interested in keeping up with our progress
        and insights, check out the following link.{" "}
        <RenderLink
          href="https://github.com/FAIR-DS4NFDI/wiki"
          text="FAIRDS Project Wiki"
        />
        <br />
        <br />
        Additionally, we've successfully installed the PADME Personal Health
        Train (PHT) on the FAIRDS Cloud environment! 🌐 This marks a significant
        step in our commitment to secure, privacy-preserving data analysis in
        healthcare.
        <br />
        <br />
        Explore more about PADME PHT and its integration here:{" "}
        <RenderLink
          href="https://fair-ds4nfdi.github.io/wiki/padme.html"
          text="PADME PHT on FAIRDS Cloud"
        />
        <br />
        <br />
        <strong>
          #FAIRDS4NFDI #HealthData #CloudComputing #DataPrivacy #PADME #PHT
          #DataScience
        </strong>
      </>
    ),
  },
  {
    title: "MII Academy",
    date: "April 12, 2024",
    author: "Karl Kindermann",
    image: "./assets/blogs/mii-academy.png",
    smallWidth: true,
    description: (
      <>
        The{" "}
        <RenderLink
          href="https://www.medizininformatik-initiative.de/en/start"
          text="Medical Informatics Initiative (MII)"
        />
        has been establishing a number of processes to make data from healthcare
        available to research. With their new online learning portal MII-Academy
        they support researchers in using the services and processes created by
        the MII. Video tutorials provide you with information and practical
        instructions on how to gain access to routine medical data for your
        research projects, how to use it effectively and how to analyze it
        optimally.
        <br />
        <br />
        Visit the{" "}
        <RenderLink
          href="https://www.smith.care/en/education/mii-academy/mii-academy-structure-and-content/"
          text="MII Academy Website"
        />{" "}
        for access and further information.
      </>
    ),
  },
  {
    title: "PADME at the SHIFT-HUB Online DemoDay",
    date: "March 23, 2024",
    author: "Karl Kindermann",
    image: "./assets/blogs/shifthub-demo.png",
    smallWidth: true,
    description: (
      <>
        On March 19<sup>th</sup>, we presented a live demo of PADME at the
        SHIFT-HUB Online DemoDay Cologne. SHIFT-HUB aims at establishing a
        pan-European Smart Health Innovation Hub, to foster the uptake of Smart
        health Technologies and services. During the event, we showcased the
        capabilities of PADME and how it can be used to conduct
        privacy-preserving data analysis. The demo was well-received, and we
        received positive feedback from the audience. We look forward to future
        collaborations with SHIFT-HUB and other partners.
        <br />
        <br />
        Find a video of the event on YouTube:{" "}
        <RenderLink
          href="https://youtu.be/tYSWjufZHS8?feature=shared"
          text="SHIFT-HUB Online DemoDay Cologne"
        />
      </>
    ),
  },
  {
    title: (
      <>
        18<sup>th</sup> Annual Meeting DGEpi 2023
      </>
    ),
    date: "October 9, 2023",
    author: "Sascha Welten",
    image: "./assets/projects/nfdi_health_squared.png",
    smallWidth: true,
    description: (
      <>
        From September 26<sup>th</sup>-28<sup>th</sup>, we participated in the
        yearly gathering of the German epidemiology community (DGEpi) held in{" "}
        <RenderLink href="https://2023.dgepi.de/" text="Würzburg" />.
        <br />
        <br />
        During one of the workshops, we introduced the idea and benefits of the
        Personal Health Train (PHT) using PADME. We showcased the ease of
        conducting data analyses and highlighted how PHT/PADME can foster
        collaborations among various institutions.
      </>
    ),
  },
  {
    title: "Station Software New User Interface Release",
    date: "October 5, 2023",
    author: "Muhammad Hamza Akhtar",
    image: "./assets/logo-black.png",
    smallWidth: true,
    description: (
      <>
        We are excited to announce the release of our new user interface for the
        Station Software. We've revamped our navigation to make finding what you
        need quicker and more intuitive. The new user interface is modern with a
        much cleaner layout that guides you seemlessly through our services.
        <br />
        <br />
        To get started, please visit our documentation page{" "}
        <RenderLink
          href="https://docs.padme-analytics.de/en/how-to/initial-station-setup#software-setup-new"
          text="here"
        />
        {". "}
        We hope you enjoy the new look and feel of the Station Software!
      </>
    ),
  },
  {
    title: "PADME at the NFDI4Health consortia meeting",
    date: "August 23, 2023",
    author: "Sven Weber",
    image: "./assets/projects/nfdi_health_squared.png",
    bigImage: "./assets/blogs/NFDI_Poster_Consortia_Meeting_Cologne_23.jpg",
    smallWidth: true,
    description: (
      <>
        On the 5th-7th of July 2023, the PADME team took part in the NFDI4Health
        consortia meeting in Cologne. During the meeting, the team presented a
        poster about joint work with Fraunhofer MEVIS on medical imaging AI. The
        poster describes how the Federated Learning (FL) extension of PADME,
        developed for NFDI4Health, was used in collaboration with MEVIS'
        competence in radiomics to conduct a Proof-of-Concept use case on
        privacy-preserving kidney tumor research. In the use case, we
        distributed open-source data from the{" "}
        <RenderLink href="https://kits19.grand-challenge.org/" text="KiTS19" />{" "}
        challenge to two sides and performed segmentation of the Kidney CT scans
        using{" "}
        <RenderLink
          href="https://totalsegmentator.com/"
          text="TotalSegmentator"
        />{" "}
        to distinguish the right and left kidney. From the segmentations, we
        extracted radiomics features, which provide an anonymous description of
        the CT's properties. Using PADME, we collected those features from the
        sides, combined them, and performed a cluster analysis to find
        correlations between the features and tumor cases from the dataset.
        <br />
        <br />
        The conduct of the use case demonstrates the capability of the PADME FL
        solution to enable privacy-preserving clinical research. Building on
        those capabilities and the established relationship between PADME and
        Fraunhofer MEVIS, we are currently pursuing additional clinical use
        cases in collaboration with the University Hospital Cologne.
        <br />
        <br />
        Pictures from the consortia meeting and further information can be found
        on the{" "}
        <RenderLink
          href="https://www.nfdi4health.de/en/news-eng/latest-news/item/228-nfdi4health-meeting-in-cologne.html"
          text="NFDI4Health website"
        />
        . Moreover, we will give a presentation on the described use case in the{" "}
        <RenderLink
          href="https://www.nfdi.de/cordi-2023/"
          text="CoRDI conference"
        />
        , where an extended abstract with the title "Distributed
        Privacy-Preserving Data Analysis in NFDI4Health with the Personal Health
        Train" was accepted. The poster from the consortia meeting can be found
        in the following:
      </>
    ),
  },
  {
    title:
      "Introducing PADME Conductor Beta: Streamlining the Analysis Development",
    date: "May 26, 2023",
    author: "Sascha Welten",
    image: "./assets/blogs/conductor.png",
    description: (
      <>
        We are happy to announce the introduction of the PADME Conductor
        library. By leveraging PADME Conductor's pre-built functions, developers
        can eliminate the need to write complex code from scratch, saving time
        and effort. This library offers a comprehensive toolkit for data
        processing, thereby allowing developers to focus on deriving valuable
        insights rather than grappling with technical complexities. The code of
        the library is{" "}
        <RenderLink
          href="https://github.com/sawelt/padme-conductor"
          text="available on GitHub"
        />{" "}
        and{" "}
        <RenderLink
          href="https://pypi.org/project/padme-conductor/"
          text="can be installed via pip in python."
        />{" "}
      </>
    ),
  },
  {
    title: "PADME in the SMITH News-Blog",
    date: "November 15, 2022",
    author: "Sascha Welten",
    image: "./assets/logo-black.png",
    smallWidth: true,
    description: (
      <>
        Our PADME infrastructure has been presented in the SMITH News-Blog. More
        information can be found{" "}
        <RenderLink href="https://www.smith.care/en/2022/11/15/" text="here." />{" "}
      </>
    ),
  },
  {
    title: "Talk at miracum-difuture colloquium",
    date: "October 31, 2022",
    author: "Sascha Welten",
    image: "./assets/logo-black.png",
    smallWidth: true,
    description: (
      <>
        We presented the current status of the implementation of PADME at the
        miracum-difuture-colloquium. You can find information about the
        presentation{" "}
        <RenderLink
          href="https://sites.google.com/master-bids.de/miracum-difuture-kolloquium/miracum-difuture-kolloquium/2022/2022-10-25-implementierung-des-personal-health-train-im-smith-projekt"
          text="here."
        />{" "}
        Read here more about{" "}
        <RenderLink
          href="https://www.miracum.org/2022/10/18/miracum-beim-jahressymposium-der-medizininformatik-initiative"
          text="miracum"
        />{" "}
        and <RenderLink href="https://difuture.de" text="difuture" />.
      </>
    ),
  },
  {
    title: "New Homepage & Landing page Launch",
    date: "July 1, 2022",
    author: "Sascha Welten",
    image: "./assets/logo-black.png",
    smallWidth: true,
    description:
      "Our new online presence has been launched! On this facelifted landing page, you can find infos about PADME and get access to our services.",
  },
  {
    title: "Accepted paper for Methods of Information in Medicine journal",
    date: "January 17, 2022",
    author: "Sascha Welten",
    image: "./assets/blogs/books.jpeg",
    description:
      "Our paper on A Privacy-Preserving Distributed Analytics Platform for Health Care Data will be published in the Methods of Information in Medicine journal.",
  },
  {
    title: "Prof. Beyan at Digital Health Europe Summit",
    date: "October 13, 2021",
    author: "Sascha Welten",
    image: "./assets/blogs/event.jpeg",
    description:
      "Prof. Beyan attended a panel on Distributed Analytics at the Digital Health Europe Summit . During this panel, experts discussed solutions for on-demand access to federated datasets and to cloud computing resources, and how these have the potential to accelerate the digital transformation of health and care (Ref: https://digitalhealtheurope.eu/dhe-summit/).",
  },
  {
    title: "Exploring new Domains",
    date: "October 7, 2021",
    author: "Sascha Welten",
    image: "./assets/blogs/lightning.jpeg",
    description:
      "For the next 12 months, we are exploring the applicability of PADME in an other domain beyond healthcare. As part of the SFC “AI, Simulation and Data Science”, we are going to explore the applicability of PADME in early warning systems for floods. Connecting multiple decentralised datasets for flood predictions might improve their reliability. Let's see what we can do there.",
  },
  {
    title: "Station Registry online!",
    date: "September 3, 2021",
    author: "Sascha Welten",
    image: "./assets/blogs/Building.jpeg",
    description: (
      <>
        We are happy to announce that we successfully linked our PADME ecosystem
        with the Station Registry from our partners in Mittweida/Leipzig. Now,
        the Station on-boarding process is semi-automated and the corresponding
        overhead is reduced significantly. The Station Registry can be found
        here:{" "}
        <RenderLink
          href="https://station-registry.de"
          text="https://station-registry.de"
        />
      </>
    ),
  },
  {
    title: "Accepted paper at PDFL workshop 2021",
    date: "August 18, 2021",
    author: "Yongli Mou",
    image: "./assets/blogs/event.jpeg",
    description:
      "Our paper Optimized Federated Learning on Class-biased Distributed Data Sources is accepted for presentation at the PDFL workshop 2021 in conjunction with ECML PKDD. The paper will be published in a combined volume ECML PKDD Workshops.",
  },
  {
    title: "Accepted paper for Data Intelligence journal",
    date: "May 10, 2021",
    author: "Sascha Welten",
    image: "./assets/blogs/books.jpeg",
    description:
      "Our paper DAMS: A Distributed Analytics Metadata Schema will be published in the Data Intelligence journal.",
  },
  {
    title: "Accepted Paper at MIE21",
    date: "May 5, 2021",
    author: "Sascha Welten",
    image: "./assets/blogs/event.jpeg",
    description:
      "Our paper Distributed Skin Lesion Analysis across Decentralised Data Sources has been accepted for MIE21.",
  },
  {
    title: "Re-deploy of PADME Central Service was successfull",
    date: "April 30, 2021",
    author: "Sascha Welten",
    image: "./assets/blogs/Building.jpeg",
    description: (
      <>
        The growth of our infrastructure forced us to re-structure our central
        server. All services are now accessible via our new web-portal:{" "}
        <RenderLink
          href="https://menzel.informatik.rwth-aachen.de:3005"
          text="https://menzel.informatik.rwth-aachen.de:3005"
        />
        <br />
        <br />
        Stay tuned!
      </>
    ),
  },
];

export { blogPosts };
