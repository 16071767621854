import { Fragment } from "react";
import Typography from "@mui/material/Typography";
import { RenderLink } from "../../utils";

const conferences = [
  {
    year: 2024,
    publications: [
      {
        authors:
          "Maia, M.; Jaberansary, M.; Hamza Akhtar, Muhammad; Ucer, Y.; Beyan, O.; Kirsten, T",
        title:
          "Training Federated Liver Cancer Image Segmentation Models using PHT Infrastructure",
        conference:
          "Proceedings of the 69. Jahrestagung der Deutschen Gesellschaft für Medizinische Informatik, Biometrie und Epidemiologie e. V. (GMDS), 15. Jahreskongress der Technologie- und Methodenplattform für die vernetzte medizinische Forschung e. V. (TMF)",
        doi: "10.3205/24gmds225",
        link: "https://doi.org/10.3205/24gmds225",
      },
    ],
  },
  {
    year: 2023,
    publications: [
      {
        authors: "Maia, M.; Jaberansary, M.; Ucer, Y.; Beyan, O.; Kirsten, T",
        title:
          "Incremental Machine Learning using Distributed Data Processing Techniques for Malaria Data Across Multiple Online Sources",
        conference:
          "Proceedings of the 68. Jahrestagung der Deutschen Gesellschaft für Medizinische Informatik, Biometrie und Epidemiologie e. V. (GMDS)",
        doi: "10.3205/23gmds049",
        link: "https://doi.org/10.3205/23gmds049",
      },
      {
        authors:
          "Zeyd Boukhers, Arnim Bleier, Yeliz Ucer Yediel, Mio Hienstorfer-Heitmann, Mehrshad Jaberansary, Sascha Welten, Adamantios Koumpis, Oya Beyan",
        title:
          "PADME-SoSci: A Platform for Analytics and Distributed Machine Learning for the Social Sciences",
        conference:
          "2023 ACM/IEEE Joint Conference on Digital Libraries (JCDL)",
        doi: "10.1109/JCDL57899.2023.00047",
        link: "https://doi.org/10.1109/JCDL57899.2023.00047",
      },
      {
        authors:
          "Mehrshad Jaberansary, Macedo Maia, Yeliz Ucer Yediel, Oya Beyan, Toralf Kirsten",
        title: "Analyzing Distributed Medical Data in FAIR Data Spaces",
        conference:
          "Companion Proceedings of the ACM Web Conference 2023 (WWW '23 Companion)",
        doi: "10.1145/3543873.3587663",
        link: "https://doi.org/10.1145/3543873.3587663",
      },
    ],
  },
  {
    year: 2022,
    publications: [
      {
        authors: "Maia, M.; Jaberansary, M.; Ucer, Y.; Beyan, O.; Kirsten, T",
        title:
          "Providing Publicly Available Medical Data Access under FAIR Principles for Distributed Analysis",
        conference:
          "Proceedings of the 67. Jahrestagung der Deutschen Gesellschaft für Medizinische Informatik, Biometrie und Epidemiologie e. V. (GMDS), 13. Jahreskongress der Technologie- und Methodenplattform für die vernetzte medizinische Forschung e. V. (TMF)",
        doi: "10.3205/22gmds071",
        link: "https://doi.org/10.3205/22gmds071",
      },
      {
        authors:
          "Yongli Mou, Jiahui Geng, Sascha Martin Welten, Chunming Rong, Stefan Josef Decker, Oya Deniz Beyan",
        title:
          "Optimized Federated Learning on Class-Biased Distributed Data Sources",
        conference:
          "Machine Learning and Principles and Practice of Knowledge Discovery in Databases",
        doi: "10.1007/978-3-030-93736-2_13",
        link: "https://doi.org/10.1007/978-3-030-93736-2_13",
      },
    ],
  },
  {
    year: 2021,
    publications: [
      {
        authors:
          "Yongli Mou, Sascha Welten, Mehrshad Jaberansary, Yeliz Ucer Yediel, Toralf Kirsten, Stefan Decker, Oya Beyan",
        title:
          "Distributed Skin Lesion Analysis across Decentralised Data Sources",
        conference: "31st Medical Informatics Europe Conference",
        doi: "10.3233/SHTI210179",
        link: "https://doi.org/10.3233/SHTI210179",
      },
    ],
  },
  {
    year: 2019,
    publications: [
      {
        authors:
          "Karim, Md Rezaul, Michael Cochez, Oya Beyan, Stefan Decker, and Christoph Lange",
        title:
          "OncoNetExplainer: Explainable Predictions of Cancer Types Based on Gene Expression Data",
        conference:
          "2019 IEEE 19th International conference on bioinformatics and bioengineering (BIBE)",
        doi: "10.1109/BIBE.2019.00081",
        link: "https://doi.org/10.1109/BIBE.2019.00081",
      },
      {
        authors:
          "Fluck J, Pigeot I, Lindstädt B, Gübitz T, Zeeb H, Ahrens W, Löffler M, Brosteanu O, Lang U, Curdt C, Semler SC, Beyan O, Neuhausen H, Dierkes J, Sax U, Kusch H, Senst H, Muth T, Thun S, Kaiser D, Dress J, Müller W, Golebiewski M",
        title:
          "NFDI4Health: Ein Konzept für eine föderierte Forschungsdateninfrastruktur personenbezogener Gesundheitsdaten",
        conference:
          "64. Jahrestagung der Deutschen Gesellschaft für Medizinische Informatik, Biometrie und Epidemiologie e. V. (GMDS) Deutsche Gesellschaft für Medizinische Informatik, Biometrie und Epidemiologie",
        doi: "10.3205/19gmds170",
        link: "https://doi.org/10.3205/19gmds170",
      },
      {
        authors: "Karim MdR, Cochez M, Jares JB, Uddin M, Beyan O, Decker S.",
        title:
          "Drug-Drug Interaction Prediction Based on Knowledge Graph Embeddings and Convolutional-LSTM Network.",
        conference:
          "Proceedings of the 10th ACM International Conference on Bioinformatics, Computational Biology and Health Informatics (BCB '19)",
        doi: "10.1145/3307339.3342161",
        link: "https://doi.org/10.1145/3307339.3342161",
      },
    ],
  },
];

export default function Conferences() {
  return (
    <>
      <Typography
        id="conferences"
        variant="h4"
        fontWeight="bold"
        mt={10}
        mb={5}
      >
        Conferences
      </Typography>
      {conferences.map(({ year, publications }) => (
        <Fragment key={`conference-${year}`}>
          <Typography variant="h5" fontWeight="bold" mb={3}>
            {year}
          </Typography>
          {publications.map(
            ({ authors, title, conference, doi, link }, idx) => (
              <Typography
                variant="h6"
                key={title}
                mb={publications.length - 1 === idx ? 7 : 3}
              >
                {authors}; {title}. In <em>{conference}</em> ({year}); DOI:{" "}
                <RenderLink href={link} text={doi} />
              </Typography>
            )
          )}
        </Fragment>
      ))}
    </>
  );
}
