import Container from "@mui/material/Container";
import JournalsFragment from "./Journals";
import ConferenceFragment from "./Conferences";
import DataUseCaseFragment from "./DataUseCases";
import ScrollToTop from "../layout/ScrollToTop";
import Title from "../layout/Title";

const menuItems = [
  {
    title: "Journals",
    link: "#journals",
    sx: { fontWeight: "bold" },
    props: { size: "large" },
  },
  {
    title: "Conferences",
    link: "#conferences",
    sx: { fontWeight: "bold" },
    props: { size: "large" },
  },
  {
    title: "Data Use Cases",
    link: "#data-use-cases",
    sx: { fontWeight: "bold" },
    props: { size: "large" },
  },
];

const PublicationsPage = () => {
  return (
    <Container>
      <ScrollToTop />
      <Title text="Publications" menuItems={menuItems} mt={10} />
      <JournalsFragment />
      <ConferenceFragment />
      <DataUseCaseFragment />
    </Container>
  );
};

export default PublicationsPage;
