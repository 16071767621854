import { Fragment } from "react";
import Typography from "@mui/material/Typography";

const useCases = [
  {
    title: "Lung Cancer Patient Identification",
    description:
      "In the use case conducted with the UHC Radiology Department, we aimed to identify lung cancer patients, where data harmonization poses a significant challenge due to the variety of devices and protocols commonly used in practice.",
  },
  {
    title:
      "Enhancing Kidney Tumor Treatment Outcomes through Distributed Synthetic Data Analysis",
    description:
      "PHT is utilized to generate synthetic data in a distributed manner, which can assist in data harmonization efforts. The use case developed in collaboration with Fraunhofer MEVIS focuses on recognizing kidney tumors in patients using computed tomography (CT) images from two different locations where patients with known tumors have already received treatment. With this data, our use case aims to study the overall outcomes of various therapy approaches.",
  },
  {
    title: "Distributed Skin Lesion Analysis across Decentralized Data Sources",
    description:
      "We applied our architecture to Distributed Skin Lesion Analysis across Three Decentralised Data Sources. We performed a straightforward statistics study and a heavy-weight machine learning task, where we trained a classifier decentrally. ",
  },
  {
    title: "Diagnosen-Koinzidenz für Mukoviszidose und Entbindung",
    description:
      "We applied our architecture to an analysis of diagnose-coincidence for mucoviscidosis and childbirth. This data use case was under the umbrella of our CORD project, where we applied our distributed analytics infrastructure.",
  },
];

export default function DataUseCases() {
  return (
    <>
      <Typography
        id="data-use-cases"
        variant="h4"
        fontWeight="bold"
        mt={10}
        mb={5}
      >
        Data Use Cases
      </Typography>
      {useCases.map(({ title, description }) => (
        <Fragment key={`data-use-case-${title}`}>
          <Typography variant="h5" fontWeight="bold" mb={3}>
            {title}
          </Typography>
          <Typography variant="h6" mb={7}>
            {description}
          </Typography>
        </Fragment>
      ))}
    </>
  );
}
